import {
  Article,
  ArticleContentProps,
  ColumnList,
  ColumnListEntry,
  LeazardForm
} from "@nettbureau/gatsby-theme-katalog";
import { graphql } from "gatsby";
import React from "react";

interface Props {
  data: {
    article: Omit<ArticleContentProps, "type">;
    strom: { nodes: ColumnListEntry[] };
    nett: { nodes: ColumnListEntry[] };
  };
}

export default ({ data }: Props) => {
  const { article, strom, nett } = data;
  return (
    <Article sidebar={<LeazardForm />} {...article}>
      <ColumnList entries={strom.nodes} />
      <h2>Norske kraftleverandører og strømprodusenter</h2>
      <p className="ingress">
        Her finner du en liste over de største norske nettleverandørene i Norge.
        Det er disse selskapene du betaler nettleien til.
      </p>
      <ColumnList entries={nett.nodes} />
    </Article>
  );
};

export const query = graphql`
  query($articleId: String) {
    article: sanityArticle(_id: { eq: $articleId }) {
      ...SanityArticleContent
    }
    strom: allStromCompany(
      filter: { cms: { isStromleverandor: { ne: false } } }
      sort: { fields: companyName }
    ) {
      nodes {
        key: id
        title: companyName
        slug
      }
    }
    nett: allStromCompany(
      filter: { cms: { isNettleverandor: { eq: true } } }
      sort: { fields: companyName }
    ) {
      nodes {
        key: id
        title: companyName
        slug
      }
    }
  }
`;
